import { Col, Row } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react';

import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import SEO from '../../components/seo';
import TrailTitle from '../../components/TrailTitle';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

const clinic = css`
  font-weight  : bold;
  padding-right: 8px;
  width        : fit-content;
  display      : block;
  &:           : after {
    content   : '';
    width     : 96px;
    height    : 2px;
    display   : inline-block;
    margin-top: 0.5rem;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 20%, rgba(194,145,46,1) 20%, rgba(194,145,46,1) 80%, rgba(0,0,0,0) 80%, rgba(0,0,0,0) 100%);
    float     : right;
  }
`;

const OutpatientPage = ({ data, location }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!open);

    return () => {
      setOpen(!open);
    };
  }, []);

  return (
    <>
      <SEO title='Layanan Rawat Jalan' />
      <Img fluid={data.outpatient.cover.value.childImageSharp.fluid} />
      <Row
        css={{
          minHeight         : '75vh',
          backgroundImage   : `url(${data.bg.childImageSharp.fixed.src})`,
          backgroundRepeat  : 'no-repeat',
          backgroundPosition: 'top right',
        }}
        className='pt-5'>
        <Col
          sm        = {'5'}
          className = 'd-flex flex-column align-items-center align-content-center'
          css       = {{ justifyContent: 'space-between' }}>
          <TrailTitle open={open} className='pb-2 mb-5'>
            <div>Layanan</div>
            <div>Rawat Jalan</div>
          </TrailTitle>
        </Col>
        <Col className='d-flex flex-column align-items-center align-content-center pl-3 pr-3 pt-1'>
          <ReactMarkdown
            source     = {data.outpatient.description.value}
            escapeHtml = {false}
          />
        </Col>
        <Col sm={{ span: '10', offset: 1 }}>
          {data.clinics.edges.map(
            ({ node: { name, description, cockpitId } }, idx) => (
              <div key={idx} className='mb-3'>
                <h5 css={clinic}>{name.value}</h5>
                {description && (
                  <ReactMarkdown
                    source     = {description.value}
                    escapeHtml = {false}
                  />
                )}
                {data.specialists.edges
                  .filter(
                    ({ node: { clinic } }) => clinic.value._id === cockpitId
                  )
                  .map(
                    (
                      {
                        node: {
                          name       : specialistName,
                          description: specialistDescription,
                          cockpitId  : specialistId,
                        },
                      },
                      idx2
                    ) => {
                      const doctors = data.doctors.edges.filter(
                        ({ node: { service } }) => 
                          service.value._id === specialistId
                      );

                      return (
                        <div
                          key       = {idx2}
                          className = {`p-3 ${
                            idx2 % 2 === 0 ? 'bg-secondary': ''
                          }`}>
                          <h6 className='font-weight-bold'>
                            {specialistName.value}
                          </h6>
                          {specialistDescription && (
                            <ReactMarkdown
                              source     = {specialistDescription.value}
                              escapeHtml = {false}
                            />
                          )}
                          {doctors.length > 0 && (
                            <>
                              <div>Dokter Gigi {specialistName.value}: </div>
                              <Row>
                                {doctors.map(
                                  (
                                    {
                                      node: {
                                        name: doctor,
                                        photo,
                                        cockpitId: doctorId,
                                      },
                                    },
                                    idx3
                                  ) => (
                                    <Col
                                      key       = {idx3}
                                      sm        = {'3'}
                                      className = 'd-flex flex-column justify-content-end align-items-center align-content-center p-2'>
                                      <Img
                                        fixed={
                                          photo?.value?.childImageSharp?.fixed
                                        }
                                        className = 'mb-2 rounded shadow-sm'
                                      />
                                      <div css={{ textAlign: 'center' }}>
                                        {doctor.value}
                                      </div>
                                    </Col>
                                  )
                                )}
                              </Row>
                            </>
                          )}
                        </div>
                      );
                    }
                  )}
              </div>
            )
          )}
        </Col>
      </Row>
    </>
  );
};

export const query = graphql`
  {
    outpatient: cockpitServices(cockpitId: { eq: "5fe05ff73735316792000065" }) {
      description {
        type
        value
      }
      cover: cover_photo {
        type
        value {
          childImageSharp {
            fluid(maxWidth: 1920, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    clinics: allCockpitClinics(
      filter: {
        cockpitId: { ne: "5fe2af736361327cb9000278" }
        display  : { value: { eq: true } }
      }
    ) {
      edges {
        node {
          cockpitId
          name {
            value
          }
          description {
            type
            value
          }
        }
      }
    }
    specialists: allCockpitSpecialists(
      filter: { display: { value: { eq: true } } }
    ) {
      edges {
        node {
          cockpitId
          name {
            value
          }
          description {
            type
            value
          }
          clinic {
            value {
              _id
            }
          }
        }
      }
    }
    doctors: allCockpitDoctors(filter: { display: { value: { eq: true } } }) {
      edges {
        node {
          service {
            value {
              _id
            }
          }
          name {
            value
          }
          photo {
            value {
              childImageSharp {
                fixed(
                  width    : 150
                  height   : 150
                  cropFocus: NORTH
                  quality  : 100
                  toFormat : WEBP
                ) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
    bg: file(name: { eq: "bg-ring" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export default OutpatientPage;
